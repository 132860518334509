import styles from './Patroller.module.css';

import { getPatroller, savePatroller } from "../../api.js";
import { useState, useEffect } from "react";

export const Patroller = ({ id, onSetPatroller }) => {
    const [ patroller, setPatroller ] = useState({ id: id, nspNumber: '', firstName: '', lastName: '', phone: '', certifications: [], email: '', photo: 'profile_s.png' });
    const [ editable, setEditable ] = useState(false);
    const [ editAction, setEditAction ] = useState('cancel');

    const handleOnChange = (event) => {
        setPatroller({ ...patroller, [ event.target.id ]: event.target.value });

        setEditAction('save');
    };

    const handleOnEdit = async () => {
        if (editable && editAction === 'save') {
            const saved = await savePatroller(patroller);

            if (saved) {
                onSetPatroller(patroller);
            }

            setEditable(!saved);
        } else if (editable && editAction === 'cancel') {
            setEditable(false);
        } else {
            setEditable(true);
            setEditAction('cancel');
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            const patrollerData = await getPatroller(id);

            setPatroller(patrollerData ?? { id: id, nspNumber: '', firstName: '', lastName: '', phone: '', certifications: [], email: '', photo: 'profile_s.png' });

            setEditable(!patrollerData);

            onSetPatroller && onSetPatroller(patrollerData);
        };

        fetchData();
    }, []);

    useEffect(() => {
        setEditAction(editable ? 'cancel' : 'edit');
    }, [ editable ]);
        
    return (
        <>
            <div className={ styles.header }>
                <span>Patroller Info</span>

                { onSetPatroller && (
                    <span className={ editable ? styles.save : styles.edit } onClick={ handleOnEdit }>{ editAction }</span>
                ) }
            </div>
            
            <div className={ styles.patroller }>
                <div className={ styles.profile }>
                    <div className={ styles.avatar }>
                        <img src={ `https://patrol360.dev/uploads/${ patroller.photo || 'profile_s.png' }` }></img>
                    </div>

                    <div className={ styles.info }>
                        { editable ? <input type="text" id="firstName" autoComplete='given-name' placeholder='First Name' required value={ patroller.firstName } onChange={ handleOnChange } /> : <span>{ `${ patroller.firstName } ${ patroller.lastName }` }</span> }
                        { editable && <input type="text" id="lastName" autoComplete='family-name' placeholder='Last Name' required value={ patroller.lastName } onChange={ handleOnChange } /> }
                        { editable ? <input type="number" id="nspNumber" autoComplete='off' placeholder='NSP Number' required value={ patroller.nspNumber } onChange={ handleOnChange } /> : <span>{ patroller.nspNumber }</span> }
                        { editable ? <input type="tel" id="phone" autoComplete='tel' placeholder='Phone' required value={ patroller.phone } onChange={ handleOnChange } /> : <span>{ patroller.phone }</span> }
                        { editable ? <input type="email" id="email" autoComplete='email' placeholder='Email' required value={ patroller.email } readOnly={ true } /> : <span>{ patroller.email }</span> }
                    </div>
                </div>

                <div className={ styles.certifications }>   
                    {
                        patroller.certifications?.map(c => {
                            return (
                                <div key={ c.id } className={ styles.badge }>
                                    <img src={ `https://patrol360.dev/assets/${ c.badge }` }></img>
                                    <span>{ c.expiry }</span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
};