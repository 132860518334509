import styles from './Duties.module.css';

import { useState } from "react";
import { LocalDate, LocalTime, LocalRelativeTime, LocalDateTime } from '../LocalDateTime/LocalDateTime.js';
import { Shift } from '../Shift/Shift.js';
import { useInterval } from '../../hooks/useInterval.js';

const Header = ({ assigned }) => {
    const [ time, setTime ] = useState(new Date().getTime());

    useInterval(() => {
        setTime(new Date().getTime());
    }, 2000);

    return (
        <div className={ styles.header }>
            <span>Assigned Shifts:<span>{ assigned }</span></span>
            <span><LocalDateTime date={ time } /></span>
        </div>
    );
};

const Status = ({ nextDuty, onOffDuty }) => {
    const offset = new Date().getTimezoneOffset() * 60000;
    const now = new Date().getTime() - offset;
    const today = new Date(now).toISOString().split('T')[0];
    const timeToNext = (nextDuty?.start - offset - now);
    const showRelativeTime = (now < nextDuty?.start - offset);
    const showActionButton = (now < nextDuty?.end - offset && now >= nextDuty?.start - (15 * 60 * 1000) - offset && !nextDuty?.stopped);
    const showNextShift = (nextDuty?.date > today);

    const handleOffDuty = () => {
        onOffDuty(nextDuty.id);
    };
    
    return (                   
        showActionButton ? (
            <button onClick={ handleOffDuty }>{ nextDuty && !nextDuty.started ? 'start shift' : 'end shift' }</button>
        ) : showRelativeTime ? (
            <p>Next shift: <LocalRelativeTime time={ timeToNext } /></p>
        ) : showNextShift ? (
            <p>Next shift: <LocalDate date={ nextDuty.start } /> at <LocalTime time={ nextDuty.start } /></p>
        ) : (
            <p>No assigned shifts</p>
        )
    );
};

export const Duties = ({ duties, onOffDuty, onViewShift, onAssignDuty }) => {
    const [ nextDuty, setNextDuty ] = useState(null);

    useInterval(() => {
        const offset = new Date().getTimezoneOffset() * 60000;
        const now = new Date().getTime() - offset;

        const filtered = (duties?.filter(d => d?.end - offset >= now));

        setNextDuty(filtered?.find(f => now < f.end && ((!f.started && !f.stopped) || (f.started && !f.stopped))));
    }, 500);

    return (
        <>
            <Header assigned={ duties?.length }  />

            <div className={ styles.duties }>
                {
                    duties?.map(d =>
                        d && (<Shift key={ d.id } shift={ d } patroller={ d } assigned={ d } nextDuty={ nextDuty } onViewShift={ onViewShift } onAssignDuty={ onAssignDuty } />)
                    )
                }

                <Status onOffDuty={ onOffDuty } nextDuty={ nextDuty } />
            </div>
        </>
    );
};