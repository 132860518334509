import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

const firebaseConfig = {
    apiKey: "AIzaSyAfZsNPN74Q6zKwOO6DJmld_WgAVF-ctiw",
    authDomain: "patrol-core.firebaseapp.com",
    projectId: "patrol-core",
    storageBucket: "patrol-core.firebasestorage.app",
    messagingSenderId: "805700869740",
    appId: "1:805700869740:web:83ff9219f97cd7562d8249",
    measurementId: "G-3KEWFH7EDN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);

// fetch configs
const fetch_err = await fetchAndActivate(remoteConfig, err => {
    console.error(err);

    return err;
});

// get remote configs
export const getConfigs = () => {
    if (fetch_err) {
        return { api_endpoint: process.env.REACT_APP_PROD_URL };
    } else if (process.env.NODE_ENV === 'development') {
        remoteConfig.settings = { minimumFetchIntervalMillis: 2000 };

        return { api_endpoint: getValue(remoteConfig, 'dev_url').asString() };
    } else {
        return { api_endpoint: getValue(remoteConfig, 'prod_url').asString() };
    }
};

// initialize authentication
export const auth = getAuth(app);

// sign out
export const signOutUser = async () => {
  await signOut(auth);
};