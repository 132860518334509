import styles from './LogInWrapper.module.css';

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { auth, signOutUser } from "../../firebase.js";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { Overlay } from '../Overlay/Overlay.js';

export const LogInWrapper = () => {
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ hasError, setHasError ] = useState(false);
    const [ showOverlay, setShowOverlay ] = useState(false);

    const handleOnChange = (event) => {
        switch (event.target.id) {
            case 'email':
                setEmail(event.target.value);

                break;
            case 'password':
                setPassword(event.target.value);

                break;
            default:
                break;
        }
    };

    const handleRegister = () => {
        createUserWithEmailAndPassword(auth, email, password)
            .then((credential) => {
                // send email verification
                sendEmailVerification(credential.user, { url: 'https://manage.patrol360.dev' });

                // sign out user
                signOutUser(auth);

                // clear error
                setHasError(false);

                // show overlay
                setShowOverlay(true);
            })
            .catch((error) => {
                setHasError(error);

                setShowOverlay(false);
            });
    };

    const handleSignIn = async () => {
        await signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                setHasError(false);
            })
            .catch(async (error) => {
                setHasError(error);
            });
    };

    const checkEmail = () => (
        <div>A verification link has been sent to your email address. Please click the link in the email to verify your account.</div>
    );

    return (
        <>
            {
                showOverlay ? <Overlay show={ setShowOverlay }>{ checkEmail() }</Overlay> 
                : !auth.currentUser ?       
                    <div className={ styles.main }>
                        <div className={ styles.login }>
                            <div id="loginInputs">
                                <input type="email" id="email" placeholder="Email" autoComplete="email" value={ email } onChange={ handleOnChange } />
                                <input type="password" id="password" placeholder="Password" autoComplete="current-password" value={ password } onChange={ handleOnChange } />
                                { hasError && <div className={ styles.error }>invalid email/password or already registered</div> }
                            </div>

                            <div id="registerSignIn">
                                <ul id="passwordRequirements">
                                    <li id="length">at least 8 characters</li>
                                    <li id="lowercase">1 lowercase letter</li>
                                    <li id="uppercase">1 uppercase letter</li>
                                    <li id="number">1 number</li>
                                    <li id="special">1 special character</li>
                                </ul>
                            
                                <div className={ styles.buttons }>
                                    <button id="registerButton" onClick={ handleRegister }>register</button>
                                    <button id="signInButton" onClick={ handleSignIn }>sign in</button>
                                </div>
                            </div>
                        </div>

                        <div id="help">
                            <Link to="/help">Need help?</Link>
                        </div>
                    </div>
                : <Navigate to="/dashboard" />
            }
        </>
    );
};