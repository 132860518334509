import styles from './ActionBar.module.css';

export const ActionBar = ({ onLogOut }) => {
    const handleHelp = () => {
        // Do something
    };
    
    return (
        <div className={ styles.bar }>
            <span className={ styles.help } onClick={ handleHelp }>help</span>
            <span className={ styles.logout } onClick={ onLogOut }>log out</span>
        </div>
    );
};