import styles from './Overlay.module.css';

export const Overlay = ({ children, show }) => {
    const handleHideOverlay = () => {
        show(false);
    };

    return (
        <div className={ styles.overlay } onClick={ handleHideOverlay }>
            <div className={ styles.content }>
                { children }
            </div>

            <div className={ styles.close }>tap anywhere to close</div>
        </div>
    );
};