const formatTime = (date) => {
    return new Date(date).toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        ampm: true,
        hour12: true
    }).replace(' AM', 'a').replace(' PM', 'p');
};

const formatRelativeTime = (diff) => {
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60) ;
    const days = Math.floor(hours / 24);

    return diff > 0 ? `${ days }d ${ hours % 24 }h ${ minutes % 60 }m ${ seconds % 60 }s` : 'calculating...';
};

const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit'
    }).replace('-', ' ');
};

export const LocalDate = ({ date }) => {
    return (
        <span>{ formatDate(date) }</span>
    );
};

export const LocalTime = ({ time }) => {
    return (
        <span>{ formatTime(time) }</span>
    );
};

export const LocalRelativeTime = ({ time }) => {
    return (
        <span>{ formatRelativeTime(time) }</span>
    );
};

export const LocalDateTime = ({ date }) => {
    return (
        <span>{ formatDate(date) } { formatTime(date) }</span>
    );
};