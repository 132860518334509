import styles from './Roster.module.css';

import { useEffect, useState } from 'react';
import { Overlay } from '../Overlay/Overlay.js';
import { Patroller } from '../Patroller/Patroller.js';

export const Roster = ({ patrollers, hillCaptain }) => {
    const [ showOverlay, setShowOverlay ] = useState(false);
    const [ patrollerId, setPatrollerId ] = useState(null);

    const handleShowPatroller = (id) => {
        setPatrollerId(id);
        setShowOverlay(!showOverlay);
    };

    if (!patrollers || patrollers.length === 0) {
        return <span>No roster found for this shift</span>;
    }

    return (
        <>
            { showOverlay && <Overlay show={ setShowOverlay }><Patroller id={ patrollerId } onSetPatroller={ null } /></Overlay> }

            <div className={ styles.roster }>
                { hillCaptain && (
                    <div className={ styles.hillCaptain }>
                        <div onClick={ () => { handleShowPatroller(hillCaptain.id) } }>
                            <img src={ `https://patrol360.dev/uploads/${ hillCaptain.photo || "profile_s.png" }` }></img>
                            <span>{ `${ hillCaptain.firstName?.charAt(0) } ${ hillCaptain.lastName }` }</span>
                        </div>

                        <span className={ styles.rightAligned }>Hill Captain</span>
                    </div>
                ) }

                { patrollers?.map(patroller => {
                    const cert = (patroller?.certifications?.find(cert => cert.name === "OEC Candidate") && 'candidate') || 'patroller';
            
                    return (
                        <div key={ patroller?.id } className={ `${ styles.patrollers } ${ styles[ cert ]}` }>
                            <div onClick={ () => { handleShowPatroller(patroller?.id) } }>
                                <img src={ `https://patrol360.dev/uploads/${ patroller?.photo || "profile_s.png" }` }></img>
                                <span>{ `${ patroller?.firstName?.charAt(0) } ${ patroller?.lastName }` }</span>
                            </div>

                            <span className={ styles.rightAligned }>Patroller</span>
                        </div>
                    );
                }) }
            </div>
        </>
    );
};