import styles from './Shifts.module.css';

import { getShifts } from "../../api.js";
import { Shift } from '../Shift/Shift.js';
import { useState, useEffect, useMemo } from 'react';

export const Shifts = ({ patroller, size = 5, onViewShift, onAssignDuty }) => {
    const [ shifts, setShifts ] = useState([]);
    const [ page, setPage ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ error, setError ] = useState(null);

    const pageSize = Math.min(size, 5) - 1;

    const memoizedShifts = useMemo(() => shifts, [shifts]);

    const getPage = (p) => {
        return memoizedShifts.filter(s => {
            const pageDate = new Date();
            const shiftDate = new Date(s.start);

            pageDate.setDate(pageDate.getDate() + (p * pageSize));

            if (p < 0) {
                return shiftDate >= pageDate && shiftDate < new Date(pageDate.getTime() + (pageSize * 24 * 60 * 60 * 1000));
            } else {
                return shiftDate <= pageDate && shiftDate > new Date(pageDate.getTime() - (pageSize * 24 * 60 * 60 * 1000));
            }
        });
    };

    // show previous/next page of shifts
    const handlePageClick = (direction) => () => {
        const newPage = page + direction;
        const newNewPage = newPage ? newPage : newPage + direction;

        if (getPage(newNewPage).length === 0) {
            return;
        } else {
            setPage(newNewPage);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const shiftsData = await getShifts();

                if (shiftsData.error) {
                    throw new Error(shiftsData.error);
                }

                setShifts(shiftsData);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    
    if (loading) {
        return <p>Loading shifts...</p>;
    }

    if (error) {
        return <p>Error loading shifts: {error}</p>;
    }

    return (
        <>
            <div className={ styles.header }>
                <span>Available Shifts</span>
                <div className={ styles.page }>
                    { getPage(page - 1).length > 0 ? ( <span><i onClick={ handlePageClick(-1) } className='fa-solid fa-angles-left fa-sm'></i></span> ) : ( <span>&nbsp;</span> ) }
                    <span>&nbsp;{ page }&nbsp;</span>
                    { getPage(page + 1).length > 0 ? ( <span><i onClick={ handlePageClick(1) } className='fa-solid fa-angles-right fa-sm'></i></span> ) : ( <span>&nbsp;</span> ) }
                </div>
            </div>

            <div className={ styles.shifts }>
                { new Date().getTime() < 0 && <div className={ styles.info }>
                    <span>Shift</span>
                    <span>Date</span>
                    <span>Start</span>
                    <span>End</span>
                    <span>&nbsp;</span>
                </div> }
                
                { getPage(page).map(f => (
                    <Shift key={ f.id } shift={ f } patroller={ patroller } assigned={ patroller.shifts?.find(s => s.id === f.id) } onViewShift={ onViewShift } onAssignDuty={ onAssignDuty }/>
                )) }
            </div>
        </>
    );
};