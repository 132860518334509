import styles from './Other.module.css';

const Header = () => {
    return (
        <div className={ styles.header }>
            <span>Other Shifts</span>
        </div>
    );
};

export const Other = ({ onOtherDuty }) => {
    const handleSignIn = (type) => {
        onOtherDuty(type);
    };

    return (
        <>
            <Header />

            <div className={ styles.other }>
                <button onClick={ handleSignIn('unscheduled') }>Unscheduled</button>
                <button onClick={ handleSignIn('training') }>Training</button>
                <button onClick={ handleSignIn('meeting') }>Meeting</button>
            </div>
        </>
    );
};